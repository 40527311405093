/** IBMPlexSans **/
@font-face {
  font-family: IBMPlexSans;
  src: url('./IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans;
  src: url('./IBMPlexSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans;
  src: url('./IBMPlexSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: IBMPlexSans;
  src: url('./IBMPlexSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/** j3mt **/
@font-face {
  font-family: j3st;
  src: url('./jest11.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
