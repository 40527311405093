html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.App {
  width: 485px;
}

.infoSwiper {
  width: 485px;
  float: left;
}
